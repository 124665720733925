<template>
  <verification-code-checker
    @change-email="changeEmail"
    @refresh="$router.push({ name: 'dashboard' }).catch(() => {})"
  ></verification-code-checker>
</template>

<script>
import VerificationCodeChecker from "@/view/components/Common/VerificationCodeChecker";
export default {
  components: { VerificationCodeChecker },
  methods: {
    changeEmail(verifyEmail) {
      this.$router.push({
        name: this.$route.query.signup ? "signup" : "signin",
        query: { email: verifyEmail },
      });
    },
  },
};
</script>
