var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('verification-code-checker', {
    on: {
      "change-email": _vm.changeEmail,
      "refresh": function refresh($event) {
        _vm.$router.push({
          name: 'dashboard'
        }).catch(function () {});
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }